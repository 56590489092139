import * as React from "react";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import loginLayout from "../layouts/login.layout";
import styled from "styled-components";
import { handleError } from "../services/other.service";
import {
  checkPhoneNumber,
  initUser,
  sendSms,
  confirmSmsCode,
  setPassword,
  logIn
} from "../services/authorization.service";
import NumberFormat from "react-number-format";
import { phoneMasks } from "../resources/constants/phone-formats";

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  @media (max-width: 512px) {
    padding: 1rem 0;
  }
`;

const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: transparent;
  border: 1px solid #6b6f82;
  color: #6b6f82;
  position: absolute;
  top: calc(50% - 12px);
  right: 12px;
  opacity: 0;
`;

const AuthInput = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #6b6f82;
  position: relative;
  &:before {
    content: '${props => props.mask || ""}';
    position: absolute;
    top: calc(50% - 0.5em);
    left: 0.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1em;
    height: 1em;
  }
  & input {
    background: transparent;
    border: 0;
    width: 100%;
    font-size: 1.125rem;
    padding: 0.5em 2.5em 0.5em 1.5em;
    border-radius: 10px;
    &:focus {
      + ${ActionButton} {
        opacity: 1
      }
    }
  }
  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  &:not(:first-of-type) {
    border-top: 0;
  }
  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const Submit = () => {
  return <ActionButton type="submit">></ActionButton>;
};

class Authorization extends React.Component {
  interval = null;
  constructor(props) {
    super(props);
    this.state = {
      userId: 0,
      phoneNumber: "",
      password: "",
      passwordConfirm: "",
      smsCode: "",
      exists: undefined,
      smsCodeConfirmed: false,
      countdown: 0
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  phoneChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value && value.replace(/ +/g, "")
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { smsCode, smsCodeConfirmed, password } = this.state;
    if (!smsCode && !smsCodeConfirmed && password) this.logIn();
    else if (smsCode && !password) this.confirmSmsCode();
    else if (smsCode && smsCodeConfirmed && password) this.setPassword();
    else if (!smsCode) this.checkPhoneNumber();
  };

  checkPhoneNumber = () => {
    const { phoneNumber, countdown } = this.state;
    checkPhoneNumber(this.state.phoneNumber)
      .then(res => {
        this.setState(
          {
            exists: res.data.exists
          },
          () => {
            if (!this.state.exists && countdown === 0) {
              // this.sendSms();
              Swal.fire(
                "Регистрация отключена",
                "На данный момент регистрация новых пользователей запрещена",
                "info"
              )
            }else if (!this.state.exists && countdown > 0)
              // Swal.fire(
              //   "",
              //   this.props.t("smsWasSent", {
              //     number: phoneNumber,
              //     timer: countdown
              //   }),
              //   "info"
              // );
              Swal.fire(
                "Регистрация отключена",
                "На данный момент регистрация новых пользователей запрещена",
                "info"
              )
            else this.password.focus();
          }
        );
      })
      .catch(err => handleError(err));
  };

  startCountdown = () => {
    this.setState(
      {
        countdown: 30
      },
      () => {
        this.interval = setInterval(() => {
          if (this.state.countdown === 0) clearInterval(this.interval);
          else {
            this.setState(prevState => ({
              countdown: prevState.countdown - 1
            }));
          }
        }, 1000);
      }
    );
  };

  sendSms = () => {
    this.startCountdown();
    initUser(this.state.phoneNumber)
      .then(() => {
        Swal.fire(
          "",
          this.props.t("smsWillSent") + this.state.phoneNumber,
          "info"
        ).then(() => {
          sendSms(this.state.phoneNumber)
            .then(res =>
              this.setState({ userId: res.data.id }, () => this.smsCode.focus())
            )
            .catch(err => handleError(err));
        });
      })
      .catch(err => handleError(err));
  };

  confirmSmsCode = () => {
    const { userId, phoneNumber, smsCode } = this.state;
    confirmSmsCode(userId, phoneNumber, smsCode)
      .then(() => this.setState({ smsCodeConfirmed: true }))
      .catch(err => handleError(err));
  };

  setPassword = () => {
    const {
      userId,
      phoneNumber,
      smsCode,
      password,
      passwordConfirm
    } = this.state;
    if (password === passwordConfirm) {
      setPassword(userId, phoneNumber, smsCode, password)
        .then(res => this.signIn(res.data))
        .catch(err => handleError(err));
    } else Swal.fire("", this.props.t("passwordsNotMatched"), "error");
  };

  logIn = () => {
    const { phoneNumber, password } = this.state;
    logIn(phoneNumber, password)
      .then(res => this.signIn(res.data))
      .catch(err => handleError(err));
  };

  signIn = ({ token, user }) => {
    const { cookies, history, dispatch } = this.props;
    const role = user && user.role,
      verified = user && user.system && user.system.verified;

    cookies.set('token', token, { path: "/" });
    cookies.set('user', user, { path: "/" });
    dispatch({ type: 'SET_USER', payload: user });

    if (verified || role === 'ADMIN') {
      history.push('/lockers')
    } else {
      history.push('/tracking')
    }
  };

  render() {
    const { t } = this.props;
    const { exists, userId, smsCodeConfirmed } = this.state;
    return (
      <LoginForm onSubmit={this.handleSubmit} autoComplete="off">
        <AuthInput mask="+">
          <NumberFormat
            type="tel"
            name="phoneNumber"
            placeholder={t("phonePlaceholder")}
            value={this.state.phoneNumber}
            onChange={this.phoneChange}
            format={phoneMasks["12"]}
            tabIndex={1}
          />
          <Submit />
        </AuthInput>
        {exists === true || smsCodeConfirmed ? (
          <React.Fragment>
            <AuthInput>
              <input
                type="password"
                name="password"
                placeholder={t("passwordPlaceholder")}
                value={this.state.password}
                onChange={this.handleChange}
                ref={passwordRef => (this.password = passwordRef)}
                tabIndex={2}
              />
              <Submit />
            </AuthInput>
            {smsCodeConfirmed ? (
              <AuthInput>
                <input
                  type="password"
                  name="passwordConfirm"
                  placeholder={t("passwordConfirmPlaceholder")}
                  value={this.state.passwordConfirm}
                  onChange={this.handleChange}
                  ref={passwordRef => (this.passwordConfirm = passwordRef)}
                  tabIndex={3}
                />
                <Submit />
              </AuthInput>
            ) : null}
          </React.Fragment>
        ) : exists === false && userId && !smsCodeConfirmed ? (
          <AuthInput>
            <input
              type="tel"
              name="smsCode"
              placeholder={t("smsCodePlaceholder")}
              value={this.state.smsCode}
              onChange={this.handleChange}
              ref={smsCodeRef => (this.smsCode = smsCodeRef)}
              tabIndex={4}
            />
            <Submit />
          </AuthInput>
        ) : null}
      </LoginForm>
    );
  }
}

export default withNamespaces("authorization")(loginLayout(Authorization));
