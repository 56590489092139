import * as React from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import styled from "styled-components";
import { withCookies } from "react-cookie";

const Text = styled.span`
  width: 100%;
  color: #6b6f82;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-size: 18px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 8px;
  transition: all 0.2s ease;
`;

export const SidebarBase = styled.ul`
  position: fixed;
  top: 70px;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 8.5rem;
  height: calc(100% - 70px);
  margin: 0;
  padding: 0;
  background: #ffffff;
  box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.25);
  color: #6b6f82;
  z-index: 9;
  overflow-y: auto;
  transition: transform 1s ease;
  @media (max-width: 768px) {
    transform: translateX(-136px);
  }
`;

const SidebarItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 8.5rem;
  margin: 0;
  background: ${props => (props.active ? "#F0F0F0" : "#FFFFFF")};
  padding: 0.875rem;
  box-sizing: border-box;
  cursor: pointer;
  > i {
    transition: all 0.2s ease;
    font-size: 3rem;
  }
  &:hover {
    background: #f9f9f9;

    i,
    span${Text} {
      transform: translateX(4px);
    }
  }
`;

class Sidebar extends React.Component {
  user = this.props.cookies.get("user") || {};
  goTo = url => {
    this.props.history.push(url);
  };

  render() {
    const { t, history } = this.props;
    const role = this.user.role,
      verified =  this.user.system && this.user.system.verified;

    const menu = [
      {
        icon: "place",
        text: "lockers",
        url: "/lockers",
        show: verified || ['SUPER_ADMIN', 'ADMIN'].includes(role)
      },
      {
        icon: "search",
        text: "tracking",
        url: "/tracking",
        show: role !== "COURIER"
      },
      {
        icon: "dashboard",
        text: "dashboard",
        url: "/dashboard",
        show: verified && role !== "COURIER"
      },
      {
        icon: "supervisor_account",
        text: "staff",
        url: "/staff",
        show: role !== "COURIER"
      },
      {
        icon: "settings",
        text: "settings",
        url: "/settings/basic-integration",
        show: role === "LOGISTICIAN" || role === "ADMIN"
      },
      { icon: "local_library", text: "FAQ", url: "/FAQ", show: false },
      { icon: "grade", text: "requests", url: "/requests", show: role === "ADMIN" },
      { icon: "book", text: "dictionaries", url: "/dictionaries", show: role === "ADMIN" },
      { icon: "monetization_on", text: "tariffs", url: "/tariffs", show: role === "ADMIN" },
      { icon: "book", text: "billing", url: "/billing", show: role === "LOGISTICIAN" || role === "ADMIN" }
    ];
    return (
      <SidebarBase>
        {menu.map((item, index) => (
          item.show ?
            <SidebarItem
              key={index}
              active={
                history.location.pathname.indexOf(item.url.split("/")[1]) > -1
              }
              onClick={() => this.goTo(item.url)}
            >
              <i className="material-icons">{item.icon}</i>
              <Text>{t(item.text)}</Text>
            </SidebarItem> : null
        ))}
      </SidebarBase>
    );
  }
}

export default withCookies(withNamespaces("sidebar")(withRouter(Sidebar)));
