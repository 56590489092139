import axios from 'axios';
const resourceUrl = '/a/orders';

export function getStatistics(from, to) {
  return axios.get(`/a/statistics${!from || !to ? '' : `?from=${from}&to=${to}`}`);
}

export function getOrders(
  perPage,
  page,
  searchKey,
  status,
  sortBy,
  order,
  from,
  to
) {
  return axios.get(
    `${resourceUrl}?limit=${perPage}&page=${page}${
      searchKey ? `&searchKey=${searchKey}` : ""
    }${status && status !== "ALL" ? `&status=${status}` : ""}${
      sortBy ? `&sortBy=${sortBy}&sort=${order}` : ""
    }${!from || !to ? "" : `&from=${from}&to=${to}`}`
  );
}

export function getOrderById(id) {
  return axios.get(`${resourceUrl}/${id}`);
}

export function changeForWithdrawal(id, type) {
  return axios.put(`${resourceUrl}/${id}/${type}-withdraw`);
}

export function downloadOrdersXls(
  status,
  sortBy,
  order,
  from,
  to
) {
  return axios({
    url: `${resourceUrl}/download?${
      status && status !== "ALL" ? `&status=${status}` : ""
    }${sortBy ? `&sortBy=${sortBy}&sort=${order}` : ""}${
      !from || !to ? "" : `&from=${from}&to=${to}`
    }`,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "tracking-report.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}

export function getOrderRatings(id, page) {
  return axios.get(`${resourceUrl}/${id}/ratings?limit=3&page=${page}`);
}

export function sendOrderReview(dto) {
  return axios.put(`${resourceUrl}/rate`, dto);
}

export function bookCell(dto) {
  return axios.post(`${resourceUrl}/insta-book`, dto);
}

export function resendSms(orderId) {
  return axios.put(`${resourceUrl}/${orderId}/sms`, {});
}

export function unBook (data) {
  return axios.put(`${resourceUrl}/unbook`, data);
}

export function toggleOrderActive (isActive, data) {
  return axios.put(`${resourceUrl}/${isActive ? 'deactivate' : 'activate'}`, data);
}

// вложение без открытия
export const fakeSent = id => axios.put(`${resourceUrl}/${id}/fake-sent`, {});

// выдача без открытия
export const fakeEnd = id => axios.put(`${resourceUrl}/${id}/fake-end`, {});

// изъятие без открытия
export const fakeWithdraw = id => axios.put(`${resourceUrl}/${id}/fake-withdraw`, {});

// список статусов
export const getStatusList = id => axios.get(`${resourceUrl}/${id}/messages`);

// переотправка статуса
export const resendStatus = id => axios.put(`${resourceUrl}/messages/${id}/resend`);

// список sms
export const getOrderSmsList = id => axios.get(`${resourceUrl}/${id}/sms`);

// переотправка sms
export const resendOrderSms = id => axios.put(`${resourceUrl}/sms/${id}/resend`);

// статус sms
export const getSmsStatus = id => axios.get(`${resourceUrl}/sms/${id}/status`);

export function transferOrder(orderId, size) {
  return axios.put(`${resourceUrl}/transfer`, { orderId, size });
}
