import * as React from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import Modal from "../../components/modal";
import {
  BasicInput,
  BasicDatepicker,
  BasicSelect
} from "../../components/basicInput";
import { Flex } from "../../components/flexboxes";
import { ActionButton } from "../../components/button";
import { createTariff } from "../../services/billing.service";
import { handleError } from "../../services/other.service";

class CreateTariff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tariff: {
        systemId: props.currentTariff.systemId || "",
        startDate: props.currentTariff.startDate
          ? moment(props.currentTariff.startDate).format("YYYY-MM-DD")
          : "",
        endDate: props.currentTariff.endDate
          ? moment(props.currentTariff.endDate).format("YYYY-MM-DD")
          : "",
        unbook: props.currentTariff.unbook || "",
        unbookPrice: props.currentTariff.unbookPrice || "",
        withdraw: props.currentTariff.withdraw || "",
        withdrawPrice: props.currentTariff.withdrawPrice || "",
        withdrawCourier: props.currentTariff.withdrawCourier || "",
        withdrawCourierPrice: props.currentTariff.withdrawCourierPrice || "",
        expiration: props.currentTariff.expiration || "",
        expirationPrice: props.currentTariff.expirationPrice || "",
        commission: props.currentTariff.commission || ""
      },
      fields: [
        { name: "systemId", type: "select" },
        { name: "startDate", type: "date" },
        { name: "endDate", type: "date" },
        { name: "unbook", type: "number" },
        { name: "unbookPrice", type: "number" },
        { name: "withdraw", type: "number" },
        { name: "withdrawPrice", type: "number" },
        { name: "withdrawCourier", type: "number" },
        { name: "withdrawCourierPrice", type: "number" },
        { name: "expiration", type: "number" },
        { name: "expirationPrice", type: "number" },
        { name: "commission", type: "number" }
      ]
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState(
      {
        tariff: {
          ...this.state.tariff,
          [name]:
            name === "startDate" || name === "endDate" || name === "systemId"
              ? value
              : parseInt(value)
        }
      },
      () => console.log(this.state.tariff)
    );
  };

  saveTariff = async e => {
    if (e) e.preventDefault();
    createTariff(this.state.tariff)
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  checkForm = () => {
    const { tariff } = this.state;
    return (
      !tariff.systemId ||
      !tariff.startDate ||
      !tariff.endDate ||
      !tariff.unbook ||
      !tariff.unbookPrice ||
      !tariff.withdraw ||
      !tariff.withdrawPrice ||
      !tariff.withdrawCourier ||
      !tariff.withdrawCourierPrice ||
      !tariff.expiration ||
      !tariff.expirationPrice ||
      !tariff.commission
    );
  };

  render() {
    const { t, systems, type, close } = this.props;
    const { fields, tariff } = this.state;
    return (
      <Modal
        id="createTariff"
        size="medium"
        title={type === "show" ? t("actions.show") : t("actions.create")}
        closeModal={() => close(null)}
      >
        <form onSubmit={this.saveTariff}>
          {fields.map((f, index) => {
            if (f.type === "date")
              return (
                <BasicDatepicker
                  key={index}
                  label={t(f.name)}
                  name={f.name}
                  min={f.name === "endDate" ? tariff.startDate : ""}
                  max={f.name === "startDate" ? tariff.endDate : ""}
                  value={tariff[f.name]}
                  onChange={this.handleChange}
                  disabled={type === "show"}
                />
              );
            else if (f.type === "select")
              return (
                <BasicSelect
                  key={index}
                  label={t(f.name)}
                  name={f.name}
                  value={tariff[f.name]}
                  options={systems}
                  onChange={this.handleChange}
                  disabled={type === "show"}
                />
              );
            else
              return (
                <BasicInput
                  key={index}
                  label={t(f.name)}
                  name={f.name}
                  value={this.state.tariff[f.name]}
                  type={f.type}
                  onChange={this.handleChange}
                  disabled={type === "show"}
                />
              );
          })}
          {type === "create" ? (
            <Flex justifyContent="flex-end" marginTop="2em">
              <ActionButton
                type="submit"
                title={t("actions.create")}
                size="small"
                click={this.saveTariff}
                disabled={this.checkForm()}
              />
            </Flex>
          ) : null}
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({ systems: state.systems });

export default withNamespaces("tariff")(connect(mapStateToProps)(CreateTariff));
