import axios from "axios";

export function blockBox(lockerId, boxId) {
  return axios.put(`/a/orders/block-box`, {
    locker: lockerId,
    box: boxId
  });
}

export function openBox(presenceCode, boxId) {
  return axios.put(`/a/orders/quick-open`, {
    locker: presenceCode,
    boxId: boxId
  });
}

export function changeBox(orderId, size, blockCurrentBox) {
  return axios.put(`/a/orders/change-box`, {
    orderId: orderId,
    size: size,
    blockCurrentBox: blockCurrentBox
  });
}

export function openOrderBox(presenceCode, pickCode) {
  return axios.put(`/a/orders/open`, {
    locker: presenceCode,
    code: pickCode
  });
}

export function boxInfo(id) {
  return axios.get(`/a/boxes/${id}/info`);
}

export function createLoan(boxId, systemId, from, to) {
  return axios.post(`/a/boxes/${boxId}/rent`, {
    systemId: systemId,
    from: from,
    to: to
  });
}

export function createOrder(boxId, systemId, identificator) {
  return axios.put(`/a/boxes/${boxId}/add-order`, {
    systemId: systemId,
    identificator: identificator,
  });
}

export function editLoan(boxId, systemId, from, to) {
  return axios.put(`/a/boxes/${boxId}/rent`, {
    systemId: systemId,
    from: from,
    to: to
  });
}

export function removeLoan(id) {
  return axios.delete(`/a/boxes/${id}/rent`);
}

export function createPickPoint(lockerId, capacity) {
  return axios.post(`/a/boxes/create-pp`, {
    lockerId,
    capacity
  });
}
