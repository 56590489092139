import axios from "axios";
import moment from "moment";

export function getDebts(limit, page, systemId) {
  return axios.get(
    `/a/orders/system-debt?limit=${limit}&page=${page}${systemId ? `&system=${systemId}` : ""}`
  );
}
export function getTariffs(systemId) {
  return axios.get(`/a/tariffs${systemId ? `?system=${systemId}` : ""}`);
}
export async function createTariff(data) {
  data.startDate = await moment(data.startDate).valueOf();
  data.endDate = await moment(data.endDate).valueOf();
  // from hours to milliseconds
  data.withdraw = data.withdraw * 3600000;
  data.withdrawCourier = data.withdrawCourier * 3600000;
  data.expiration = data.expiration * 3600000;
  data.unbook = data.unbook * 3600000;
  return axios.post(`/a/tariffs`, data);
}
export function removeTariff(systemId, id) {
  return axios.delete(`/a/tariffs?system=${systemId}&id=${id}`);
}

export function downloadDebtXls(systemId) {
  return axios({
    url: `/a/orders/system-debt/download${systemId ? `?system=${systemId}` : ""}`,
    method: "GET",
    responseType: "blob"
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "debt-report.xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}
