import * as React from "react";
import { withNamespaces } from "react-i18next";
import Modal from "../../components/modal";
import { ActionButton, Row, BasicInput, BasicPhoneInput, BasicSelect } from "../../components";
import { addUser, editUser, toggleUser } from "../../services/staff.service";
import { handleError } from "../../services/other.service";
import { phoneFormats } from "../../resources/constants/phone-formats";
import { roles } from "../../resources/constants/roles";
import moment from "moment";

class AddEmployee extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      birthDate: '',
      phone: '',
      email: '',
      role: ''
    }
  }

  componentDidMount() {
    if (this.props.chosenEmployee) {
      this.setState({
        ...this.state,
        ...this.props.chosenEmployee
      })
    }
  }

  handleChange = ({ target: { name, value } }) => {
    this.setState({
      [name]:
        name === "phone"
          ? value.substring(1, value.length).replace(/ +/g, "") //remove prefix and spaces between digits
          : name === "birthDate"
          ? new Date(value).getTime()
          : value
    });
  }

  handleSubmit = e => {
    e.preventDefault();

    if (!!this.state.id) {
      editUser(this.state, this.props.close(true))
    } else {
      addUser(this.state, this.props.close(true));
    }
  };

  toggleUser = user => {
    toggleUser(user.id, user.enabled ? 'disable' : 'enable')
      .then(() => this.props.close(true))
      .catch(err => handleError(err));
  };

  checkForm = () => {
    const { state } = this;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      !state.firstname ||
      !state.lastname ||
      !state.birthDate ||
      !state.role ||
      !re.test(state.email.toLowerCase()) ||
      state.phone.length < 12
    );
  };

  render() {
    const { state } = this;
    const { t, close } = this.props;
    return (
      <Modal size="small" title={t(state.id ? 'staff:actions.edit' : 'title')} closeModal={close}>
        <form onSubmit={this.handleSubmit} autoComplete="off">
          <BasicInput
            label={t("firstName")}
            icon="person"
            name="firstname"
            value={state.firstname}
            type="text"
            onChange={this.handleChange}
            required
          />
          <BasicInput
            label={t("lastName")}
            icon="person"
            name="lastname"
            value={state.lastname}
            type="text"
            onChange={this.handleChange}
            required
          />
          <BasicInput
            label={t("birthDate")}
            icon="date_range"
            name="birthDate"
            value={moment(state.birthDate).format("YYYY-MM-DD")}
            type="date"
            onChange={this.handleChange}
            required
          />
          <BasicInput
            label={t("email")}
            icon="email"
            name="email"
            value={state.email}
            type="email"
            onChange={this.handleChange}
            required
          />
          <BasicPhoneInput
            label={t("phone")}
            icon="phone_iphone"
            name="phone"
            value={state.phone}
            onChange={this.handleChange}
            format={phoneFormats["12"]}
            required
          />
          {
            !state.id ? (
              <BasicSelect
                name="role"
                label={t('role')}
                value={state.role}
                options={roles}
                onChange={this.handleChange}
                required
              />
            ) : null
          }
          <Row
            justifyContent={!!state.id ? "space-between" : "flex-end"}
            marginTop="2rem"
            fullWidth
          >
            {!!state.id ? (
              <React.Fragment>
                <ActionButton
                  backgroundColor={state.enabled ? '#FF4607' : '#02c100'}
                  title={t(state.enabled ? 'deactivate' : 'activate')}
                  icon={state.enabled ? 'block' : 'check'}
                  size="small"
                  click={() => this.toggleUser(state)}
                />
                <ActionButton
                  type="submit"
                  title={t("save")}
                  icon="save"
                  size="small"
                />
              </React.Fragment>
            ) : (
              <ActionButton
                type="submit"
                title={t("invite")}
                icon="person_add"
                size="small"
                disabled={this.checkForm()}
              />
            )}
          </Row>
        </form>
      </Modal>
    );
  }
}

export default withNamespaces("addEmployee")(AddEmployee);
