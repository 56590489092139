import * as React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import * as TrackingService from "../../services/tracking.service";
import { handleError, questionAlert, notify } from "../../services/other.service";
import { openOrderBox, changeBox } from "../../services/boxes.service";
import Modal, { ModalItem, Text, Title } from "../../components/modal";
import {
  Timeline,
  FirstPoint,
  SecondPoint,
  ThirdPoint,
  PointUser
} from "../../components/timeline";
import {
  LabelWithBorder,
  ActionButton,
  Button,
  Close,
  FlexColumn,
  FlexRow,
  Checkbox,
  Radio
} from "../../components";
import { Review, WriteReview } from "../../components/review";
import { Table, Td, Th, Tr } from "../../components/flexibleTable";

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeBox: false,
      size: null,
      blockCurrentBox: false,
      reviews: [],
      reviewsCount: null,
      comment: "",
      rating: 0,
      showReviewInput: false,
      page: 0,
      list: [],
      listType: ''
    };
  }

  componentDidMount() {
    this.getReviews(true);
  }

  getReviews = refresh => {
    TrackingService.getOrderRatings(this.props.order.id, this.state.page)
      .then(res => {
        const reviews = !refresh ? this.state.reviews : res.data.list;
        if (!refresh) res.data.list.forEach(t => reviews.push(t));
        this.setState({
          reviews,
          reviewsCount: res.data.count
        });
      })
      .catch(err => handleError(err));
  };

  sendReview = () => {
    const { comment, rating } = this.state;
    const dto = {
      identifier: this.props.order.id,
      rating,
      comment
    };
    TrackingService.sendOrderReview(dto)
      .then(() =>
        this.setState(
          {
            page: 0,
            comment: "",
            rating: "",
            showReviewInput: false
          },
          () => this.getReviews(true)
        )
      )
      .catch(err => handleError(err));
  };

  showMore = () => {
    this.setState(
      prevState => ({
        page: ++prevState.page
      }),
      () => this.getReviews()
    );
  };

  showPaymentInfo() {
    const { t, order } = this.props;
    const info = [
      { name: t('price'), value: order.price },
      { name: t('paidValue'), value: order.price - order.value},
      { name: t('paymentStatus'), value: t(order.value === 0 ? 'paid' : 'notPaid') }
    ];
    return info.map((i, index) => (
      <ModalItem key={index}>
        <Title>{i.name}: </Title>
        <Text>{i.value}</Text>
      </ModalItem>
    ));
  }

  showInfo() {
    const { t, order } = this.props;
    const info = [
      { name: t("phoneNumber"), value: order.client.phone },
      { name: t("email"), value: order.client.email },
      { name: t("address"), value: order.client.address },
      { name: t("device"), value: order.device && order.device.device },
      { name: t("os"), value: order.device && order.device.osName },
      {
        name: t("webBrowser"),
        value: order.device && order.device.browserName
      },
      {
        name: t("lastVisit"),
        value:
          order.device &&
          moment(order.device.createDate).format("DD.MM.YY - HH:mm")
      }
    ];
    return info.map((i, index) => (
      <ModalItem key={index}>
        <Title>{i.name}</Title>
        <Text>{i.value ? i.value : t("notYetDetected")}</Text>
      </ModalItem>
    ));
  }

  handleChange = e => {
    const { name, value, checked } = e.target;
    this.setState({ [name]: name === "blockCurrentBox" ? checked : value });
  };

  openChangeBoxBlock = () => {
    this.setState({ changeBox: true }, () => {
      const modal = document.getElementById("orderDetails");
      modal.scrollTop = modal.scrollHeight;
    });
  };

  openBox = () => {
    const { t, order, reload } = this.props;
    questionAlert(t('cellInfo:toOpen'), () =>
      openOrderBox(order.locker.presenceCode, order.pickCode)
        .then(() => notify('success', t('cellInfo:wasOpened'), null, reload))
        .catch(err => handleError(err))
    );
  };

  resendSms = () => {
    const { t, order, reload } = this.props;
    questionAlert(t('cellInfo:resendSms'), () =>
      TrackingService.resendSms(order.id)
        .then(() => notify('success', t('cellInfo:smsSent'), null, reload))
        .catch(err => handleError(err))
    );
  };

  changeBox = () => {
    const { t, order, reload } = this.props;
    const { size, blockCurrentBox } = this.state;
    questionAlert(t("cellInfo:toChange"), () =>
      changeBox(order.id, size, blockCurrentBox)
        .then(() => notify('success', t('cellInfo:wasChanged'), null, reload))
        .catch(err => handleError(err))
    );
  };

  unBook = async () => {
    const { t, user, order, close } = await this.props;
    const data = await { identificator: order.identificator };
    if (user.role === "ADMIN")
      data.systemId = (await order.bookSystem) && order.bookSystem.id;
    questionAlert(t("cellInfo:toCancelBook"), () =>
      TrackingService.unBook(data)
        .then(() => close(true, false))
        .catch(err => handleError(err))
    );
  };

  toggleActive = () => {
    const { t, order, reload } = this.props;
    const alertTitle = order.active ? t('toDeactivate') : t('toActivate');
    const successTitle = order.active ? t('wasDeactivated') : t('wasActivated');
    const data = {
      locker: order.locker.code,
      code: order.dropCode,
      system: order.bookSystem.id
    };
    questionAlert(alertTitle, () => {
      TrackingService.toggleOrderActive(order.active, data)
        .then(() => notify('success', successTitle, null, reload))
        .catch(err => handleError(err))
    });
  };

  fakeSent = () => {
    const { order, close } = this.props;
    TrackingService.fakeSent(order.id)
      .then(() => close(true, false))
      .catch(err => handleError(err))
  };

  fakeEnd = () => {
    const { order, close } = this.props;
    TrackingService.fakeEnd(order.id)
      .then(() => close(true, false))
      .catch(err => handleError(err))
  };

  fakeWithdraw = () => {
    const { order, close } = this.props;
    TrackingService.fakeWithdraw(order.id)
      .then(() => close(true, false))
      .catch(err => handleError(err))
  };

  scrollToTable = () => {
    try {
      const table = document.getElementById('listTable');
      table.scrollIntoView({ behavior: 'smooth' });
    } catch (e) {}
  }

  getStatusList = () => {
    TrackingService.getStatusList(this.props.order.id)
      .then(({ data }) => {
        this.setState({ listType: 'status', list: data && data.list }, () => this.scrollToTable())
      })
      .catch(err => handleError(err))
  };

  resendStatus = statusId => () => {
    const { t, reload } = this.props;
    TrackingService.resendStatus(statusId)
      .then(() => notify('success', t('statusWasResent'), null, reload))
      .catch(err => handleError(err))
  };

  getOrderSmsList = () => {
    TrackingService.getOrderSmsList(this.props.order.id)
      .then(({ data }) => {
        this.setState({ listType: 'sms', list: data && data.list }, () => this.scrollToTable())
      })
      .catch(err => handleError(err))
  };

  resendOrderSms = smsId => () => {
    const { t, reload } = this.props;
    TrackingService.resendOrderSms(smsId)
      .then(() => notify('success', t('smsWasResent'), null, reload))
      .catch(err => handleError(err))
  };

  getSmsStatus = smsId => () => {
    TrackingService.getSmsStatus(smsId)
      .then(({ data }) => notify('info', data && data.status, null, this.props.reload))
      .catch(err => handleError(err))
  };

  parseData = data => {
    try {
      const obj = JSON.parse(data);
      return Object.entries(obj)
        .filter(([key]) => ['recievedDate', 'receivedDate', 'sentDate'].includes(key))
        .map(([key, value]) => `${this.props.t(key)}: ${moment(value).format('DD.MM.YY - HH:mm')}`)
    } catch (e) {
      return [this.props.t('statusRequestHasNotBeenSent')]
    }
  }

  render() {
    const { t, user, order, changeForWithdrawal, close } = this.props;
    const {
      changeBox,
      size,
      blockCurrentBox,
      comment,
      reviews,
      reviewsCount,
      showReviewInput,
      list,
      listType
    } = this.state;
    const cellSizes = ["S", "M", "L"];
    return order ? (
      <Modal
        id="orderDetails"
        size="medium"
        title={order.identificator}
        closeModal={() => close(false, false)}
      >
        <Timeline>
          <FirstPoint active={order.bookDate}>
            {order.bookDate ? (
              <React.Fragment>
                {moment(order.bookDate).format("DD.MM.YY - HH:mm")}
                {t("booked", {
                  cellsize: order.box.size,
                  index: order.locker.index
                })}
                {order.bookUser &&
                order.bookUser.firstname &&
                order.bookUser.lastname ? (
                  <PointUser>{`${order.bookUser.firstname} ${
                    order.bookUser.lastname
                  }`}</PointUser>
                ) : order.bookUser && order.bookUser.role ? (
                  <PointUser>
                    {t(`staff:roles.${order.bookUser.role}`)}
                  </PointUser>
                ) : null}
              </React.Fragment>
            ) : null}
          </FirstPoint>
          <SecondPoint active={order.dropDate}>
            {order.dropDate ? (
              <React.Fragment>
                {moment(order.dropDate).format("DD.MM.YY - HH:mm")}
                {t('dropped', { column: order.box.column, row: order.box.row })}
                {order.dropUser &&
                order.dropUser.firstname &&
                order.dropUser.lastname ? (
                  <PointUser>{`${order.dropUser.firstname} ${
                    order.dropUser.lastname
                  }`}</PointUser>
                ) : order.dropUser && order.dropUser.role ? (
                  <PointUser>
                    {t(`staff:roles.${order.dropUser.role}`)}
                  </PointUser>
                ) : null}
              </React.Fragment>
            ) : null}
          </SecondPoint>
          <ThirdPoint active={order.pickDate || order.withdrawDate}>
            {order.pickDate || order.withdrawDate ? (
              <React.Fragment>
                {moment(order.pickDate || order.withdrawDate).format(
                  "DD.MM.YY - HH:mm"
                )}
                {order.pickDate
                  ? t("handed")
                  : order.withdrawDate
                  ? t("withdrawn")
                  : null}
                {order.withdrawUser &&
                order.withdrawUser.firstname &&
                order.withdrawUser.lastname ? (
                  <PointUser>{`${order.withdrawUser.firstname} ${
                    order.withdrawUser.lastname
                  }`}</PointUser>
                ) : order.withdrawUser && order.withdrawUser.role ? (
                  <PointUser>
                    {t(`staff:roles.${order.withdrawUser.role}`)}
                  </PointUser>
                ) : null}
              </React.Fragment>
            ) : null}
          </ThirdPoint>
        </Timeline>
        {order.price > 0 ? (
          <React.Fragment>
            <LabelWithBorder>{t('paymentInfo')}</LabelWithBorder>
            {this.showPaymentInfo()}
          </React.Fragment>
        ) : null}
        {order.client ? (
          <React.Fragment>
            <LabelWithBorder>{t("receiverInfo")}</LabelWithBorder>
            {this.showInfo()}
          </React.Fragment>
        ) : null}

        { ['SUPER_ADMIN', 'ADMIN','LOGISTICIAN', ''].includes(user.role) || (
          user.system && (user.system.verified || user.system.basicIntegration || user.system.advancedIntegration)
        ) ? (
          <>
            <LabelWithBorder>{t("global:availabileActions")}</LabelWithBorder>
            <FlexRow justifyContent="flex-start" innerSpace="0.5rem" wrapItems>
              { ['ADMIN'].includes(user.role) && !showReviewInput ? (
                <Button
                  onClick={() => this.setState({ showReviewInput: true })}
                  primary
                  small
                >
                  {t("actions.writeReview")}
                </Button>
              ) : null}
              { order.status === 'RESERVED' ? (
                <>
                  {
                    ['ADMIN', 'LOGISTICIAN', 'OPERATOR'].includes(user.role) ? (
                      <Button onClick={this.unBook} primary small fullWidth>
                        {t("actions.cancelBooking")}
                      </Button>
                    ) : null
                  }
                  {
                    user.role === 'SUPER_ADMIN' ? (
                      <Button onClick={this.fakeSent} primary small fullWidth>
                        {t("actions.fakeSent")}
                      </Button>
                    ) : null
                  }
                  {!changeBox ? (
                    <Button
                      onClick={this.openChangeBoxBlock}
                      primary
                      small
                      fullWidth
                    >
                      {t("actions.changeBox")}
                    </Button>
                  ) : null}
                </>
              ) : order.status === 'SENT' ? (
                <>
                  <Button onClick={this.openBox} primary small fullWidth>
                    {t("actions.openCellRemotely")}
                  </Button>
                  <Button onClick={this.resendSms} primary small fullWidth>
                  {t("actions.resendSms")}
                  </Button>
                  <Button onClick={this.toggleActive} primary small fullWidth>
                    {order.active
                      ? t("actions.deactivate")
                      : t("actions.activate")}
                  </Button>
                  <Button
                    primary
                    small
                    fullWidth
                    onClick={() =>
                      changeForWithdrawal(order.onWithdraw ? "from" : "to")
                    }
                  >
                    {t(
                      `actions.changeForWithdrawal.${
                        order.onWithdraw ? "from" : "to"
                      }`
                    )}
                  </Button>
                  {
                    user.role === 'SUPER_ADMIN' ? (
                      <>
                        <Button onClick={this.fakeEnd} primary small fullWidth>
                          {t("actions.fakeEnd")}
                        </Button>
                        <Button onClick={this.fakeWithdraw} primary small fullWidth>
                          {t("actions.fakeWithdraw")}
                        </Button>
                      </>
                    ) : null
                  }
                </>
              ) : null }
              {
                ['ADMIN', 'SUPER_ADMIN'].includes(user.role) ? (
                  <>
                    <Button onClick={this.getStatusList} primary small fullWidth>
                      {t("actions.statusList")}
                    </Button>
                    <Button onClick={this.getOrderSmsList} primary small fullWidth>
                      {t("actions.smsList")}
                    </Button>
                  </>
                ) : null
              }
            </FlexRow>
            {showReviewInput && (
              <WriteReview
                rating={this.state.rating}
                setRating={e => this.setState({ rating: e.target.value })}
                value={comment}
                change={e => this.setState({ comment: e.target.value })}
                send={this.sendReview}
              />
            )}
          </>
        ) : null}

        {
          list && list.length ?
            <>
              <FlexRow margin="2rem 0 1rem" justifyContent="flex-end">
                <Close click={() => this.setState({ list: [] })} />
              </FlexRow>
              <Table size={'small'} fullWidth>
                <thead id="listTable">
                <Tr>
                  <Th>{t('createdDate')}</Th>
                  <Th>{t('request')}</Th>
                  { listType === 'sms' ? <Th>{t('data')}</Th> : null }
                  <Th>{t('status')}</Th>
                  <Th>{t('url')}</Th>
                  <Th>{t('global:availabileActions')}</Th>
                </Tr>
                </thead>
                <tbody>
                {list.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.createDate && moment(item.createDate).format("DD.MM.YY - HH:mm")}</Td>
                    <Td>{item.request}</Td>
                    { listType === 'sms' ?
                      <Td>
                        <FlexRow justifyContent="flex-start" innerSpace="0.5rem" wrapItems>
                          {this.parseData(item.data).map((item, i) => <div key={i}>{item}</div>)}
                        </FlexRow>
                      </Td> : null
                    }
                    <Td>{item.status}</Td>
                    <Td>{item.url}</Td>
                    <Td>
                      {
                        item.type === 'SMS' ?
                          <FlexRow justifyContent="flex-start" innerSpace="0.5rem" wrapItems>
                            <Button onClick={this.resendOrderSms(item.id)} primary small fullWidth>
                              {t('actions.resendOrderSms')}
                            </Button>
                            <Button onClick={this.getSmsStatus(item.id)} primary small fullWidth>
                              {t('actions.getSmsStatus')}
                            </Button>
                          </FlexRow> :
                        item.type === 'PARCEL_STATUS' ?
                          <Button onClick={this.resendStatus(item.id)} primary small fullWidth>
                            {t('actions.resendStatus')}
                          </Button> :
                        null
                      }
                    </Td>
                  </Tr>
                ))}
                </tbody>
              </Table>
            </>
            : null
        }
        {/*{order.status === "END" &&*/}
        {/*(user.role === "ADMIN" ||*/}
        {/*user.system.basicIntegration ||*/}
        {/*user.system.advancedIntegration) ? (*/}
        {/*<React.Fragment>*/}
        {/*<LabelWithBorder>{t("global:availabileActions")}</LabelWithBorder>*/}
        {/*<FlexRow justifyContent="flex-start" innerSpace="0.5rem">*/}
        {/*<Button primary small fullWidth>*/}
        {/*{t("actions.downloadReceipt")}*/}
        {/*</Button>*/}
        {/*</FlexRow>*/}
        {/*</React.Fragment>*/}
        {/*) : null}*/}
        {changeBox ? (
          <FlexColumn innerSpace="0.25rem" marginTop="1rem" withBorder>
            <FlexRow alignItems="center" justifyContent="space-between">
              <Title>{t("actions.changeBox")}</Title>
              <Close click={() => this.setState({ changeBox: false })} />
            </FlexRow>
            <FlexRow innerSpace="0.5rem" fullWidth wrapItems>
              {cellSizes.map((s, index) => (
                <Radio
                  key={index}
                  id={s}
                  name={"size"}
                  title={t(`cellSizes:${s}`)}
                  value={s}
                  checked={size === s}
                  onChange={e => this.setState({ size: e.target.value })}
                />
              ))}
            </FlexRow>
            <FlexRow innerSpace="0.5rem" fullWidth wrapItems>
              <Button onClick={this.changeBox} disabled={!size} primary small>
                {t("actions.change")}
              </Button>
              <Checkbox
                id={"blockCurrentBox"}
                name={"blockCurrentBox"}
                title={t("actions.blockCurrentBox")}
                checked={blockCurrentBox === true}
                onChange={this.handleChange}
              />
            </FlexRow>
          </FlexColumn>
        ) : null}
        {user.role === "ADMIN" && reviewsCount > 0 ? (
          <FlexColumn>
            <LabelWithBorder>
              {t("reviews")} ({reviewsCount})
            </LabelWithBorder>
            {reviews.map((r, index) => (
              <Review key={index} item={r} type="order" />
            ))}
            {reviewsCount > reviews.length && (
              <ActionButton
                title={t("actions.showMore")}
                click={this.showMore}
                size={"small"}
              />
            )}
          </FlexColumn>
        ) : null}
      </Modal>
    ) : null;
  }
}

const mapStateToProps = state => ({ user: state.currentUser });

export default withNamespaces("orderDetails")(
  connect(mapStateToProps)(OrderDetails)
);
