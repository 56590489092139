import * as React from "react";
import styled from "styled-components";
import mail from "../resources/img/mail.svg";

const LockerBase = styled.table`
  font-size: 0.75rem;
  border-spacing: 0;
  border: 20px solid #ed8d6d;
  border-right-width: 6px;
  border-left-width: 6px;
  box-sizing: border-box;
`;

const Cell = styled.td`
  box-sizing: border-box;
  border: 1px solid ${props => (props.active ? "#FFF" : "#6b2812")};
  border-top: ${props => (props.active ? "1px solid #FFF !important" : "")};
  background: ${props =>
    props.hasParcel
      ? `#f05a28 url("${mail}") center center no-repeat`
      : "#f05a28"};
  background-size: ${props => props.size === "S" ? "contain" : ""};
  cursor: pointer;
  padding: ${props =>
    props.size === "L"
      ? "1.75em 3em"
      : props.size === "M"
      ? "1.25em 2em"
      : props.size === "S"
      ? "0.75em 1em"
      : "20px"};
  &:hover {
    background: ${props =>
      props.hasParcel
        ? `#b8451f url("${mail}") center center no-repeat`
        : "#b8451f"};
    background-size: ${props => props.size === "S" ? "contain" : ""};
  }
  &:not(:first-of-type) {
    border-left: ${props => (props.active ? "" : 0)};
  }
`;

const Row = styled.tr`
  &:not(:first-of-type) {
    ${Cell} {
      border-top: 0;
    }
  }
`;

export const Locker = ({ locker, chosenCell, cellClick }) => {
  return (
    <div>
      <LockerBase>
        <tbody>
          {locker.map((row, i) => (
            <Row key={i}>
              {row.map((cell, index) => (
                <Cell
                  key={index}
                  size={cell.size}
                  active={chosenCell.id === cell.id}
                  hasParcel={cell.status === "RESERVED"}
                  onClick={() => cellClick(cell.id)}
                />
              ))}
            </Row>
          ))}
        </tbody>
      </LockerBase>
    </div>
  );
};
