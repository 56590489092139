import React, {useEffect, useState} from "react";
import { withNamespaces } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { createTastamat, updateTastamat } from "../../services/lockers.service";
import { createPickPoint } from "../../services/boxes.service";
import { handleError } from "../../services/other.service";
import { initialLocker } from '../../resources/constants/locker-model';
import Modal from "../../components/modal";
import {
  LabelWithBorder,
  BasicInput,
  Label,
  FlexColumn,
  FlexRow,
  ActionButton,
  Checkbox,
  Radio
} from '../../components';

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  margin: -0.25rem;
  width: calc(100% + 0.5rem) !important;
  overflow: ${props => (props.active ? "auto" : "hidden")};
  transition: all 0.25s ease;
  max-height: ${props => (props.active ? "1120px" : "3.525rem")};
  > div {
    flex: 1 1 47%;
    margin: 0.25rem;
  }
`;

const LangBtn = styled.button.attrs(() => ({ type: 'button' }))`
  border: 0.5px solid #F15A29;
  font-weight: ${p => p.active ? 'bold' : 300};
  background: ${p => p.active ? '#F15A29' : '#ffffff'};
  color: ${p => p.active ? '#ffffff' : '#F15A29'};
  font-size: 0.875rem;
  &:not(:first-of-type) {
    border-left-width: 0;
  }
`;

const ChangeLockerInfo = props => {
  const { t, close } = props;
  const langs = ['Ru', 'Kk', 'En'];
  const types = ['OFFICE', 'ENTRANCE', 'PARKING', 'SHOP'];
  const accessTypes = ['FREE', 'INTERCOM'];
  const haveLang = ['description', 'region', 'district', 'street', 'streetShort', 'building', 'buildingShort'];
  const [locker, setLocker] = useState({ ...initialLocker });
  const [isPp, setIsPp] = useState(false);
  const [isFreeDay, setFreeDay] = useState({
    scheduleFriday: false,
    scheduleMonday: false,
    scheduleSaturday: false,
    scheduleSunday: false,
    scheduleThursday: false,
    scheduleTuesday: false,
    scheduleWednesday: false
  });
  const [dayOptions, setDayOptions] = useState({
    weekdays: false,
    alldays: false
  });
  const [ppCapacity, setPpCapacity] = useState(0);
  const [view, setView] = useState('');
  const [chosenLang, setChosenLang] = useState('Ru');

  useEffect(() => {
    if (props.locker && !locker.id) {
      setLocker({
        ...locker,
        ...props.locker,
        params: { ...locker.params, ...props.locker.params },
        location: { ...locker.location, ...props.locker.location },
        address: { ...locker.address, ...props.locker.address },
        partner: { ...locker.partner, ...props.locker.partner },
        schedule: { ...locker.schedule, ...props.locker.schedule },
      });
      Object.entries(props.locker.schedule).forEach(entry => {
        let [key, value] = entry;
        if (value === '-') {
          locker.scheduleFromTo[key+',From'] = '-';
          locker.scheduleFromTo[key+',To'] = '-';
          updateFreeDay(key, true);
        } else {
          value = value.split('-');
          locker.scheduleFromTo[key+',From'] = value[0];
          locker.scheduleFromTo[key+',To'] = value[1];
        }
      });
    }
  }, [props.locker, locker]);

  useEffect(() => {
    if (props.locker && props.locker.boxes) {
      const pp = props.locker.boxes.find(item => item.size === 'PP');
      setIsPp(!!pp);
      setPpCapacity(pp && pp.capacity ? pp.capacity : '');
    }
  }, [props.locker]);

  const handleChange = (key, e) => {
    let { type, name, value } = e.target;
    // console.log(key);
    // console.log(e);
    console.log(type, name, value);
    if (name.includes('scheduleFromTo')) {
      // let a = name.split(',')
      // name = a[0];

      // let ty = a[1];
      // console.log(ty)
      // console.log(props.locker)
      if (props.locker.scheduleFromTo === null) {
        props.locker.scheduleFromTo = { 
          "scheduleMonday,From": '00:00',
          "scheduleTuesday,From": '00:00',
          "scheduleWednesday,From": '00:00',
          "scheduleThursday,From": '00:00',
          "scheduleFriday,From": '00:00',
          "scheduleSaturday,From": '00:00',
          "scheduleSunday,From": '00:00',
          "scheduleMonday,To": '00:00',
          "scheduleTuesday,To": '00:00',
          "scheduleWednesday,To": '00:00',
          "scheduleThursday,To": '00:00',
          "scheduleFriday,To": '00:00',
          "scheduleSaturday,To": '00:00',
          "scheduleSunday,To": '00:00',
        }
      } else {
        console.log('nuuuul');
      }
    }

    if (dayOptions['alldays'] === true && name.includes('scheduleMonday')) {
      Object.entries(locker.schedule).forEach(entry => {
        let [k] = entry;
        
        console.log('ALLDAYS');

        setLocker(l => ({
          ...l,
          [key]: {
            ...l[key],
            [k+','+name.split(',')[1]]: type === 'number' ? parseFloat(value) : value
          }
        }))
      });
    } else if (dayOptions['weekdays'] === true && name.includes('scheduleMonday')) {
      Object.entries(locker.schedule).forEach(entry => {
        let [k] = entry;
        
        if (k !== 'scheduleSaturday' && k !== 'scheduleSunday') {
          setLocker(l => ({
            ...l,
            [key]: {
              ...l[key],
              [k+','+name.split(',')[1]]: type === 'number' ? parseFloat(value) : value
            }
          }))
        }
      });
    } else {
      setLocker(l => ({
        ...l,
        [key]: {
          ...l[key],
          [name]: type === 'number' ? parseFloat(value) : value
        }
      }))
    }
  };

  const contentCheck = obj => {
    let newObj = {};
    Object.keys(obj).map(item => {
      if (obj[item]) newObj[item] = obj[item];
      return null;
    });
    return newObj;
  };

  const putSchedule = () => {
    Object.entries(locker.schedule).forEach(entry => {
      let [key] = entry;
      locker.schedule[key] = locker.schedule[key] === '-'
              ? '-'
              : locker.scheduleFromTo[key+',From'] + '-' + locker.scheduleFromTo[key+',To']
      // console.log(key, value);
    });
  }

  const saveTastamat = () => {
    putSchedule();
    const params = contentCheck(locker.params);
    
    const dto = {
      ...locker,
      code: params.code || '',
      index: params.index || '',
      oldIndex: params.oldIndex || '',
      size: params.size || '',
      rows: params.rows || '',
      columns: params.columns || '',
      presenceCode: params.presenceCode || '',
      gisUrl: params.gisUrl || '',
      location: contentCheck(locker.location),
      address: contentCheck(locker.address),
      partner: contentCheck(locker.partner)
    };
    delete dto.params;

    let temp = dto
    delete temp.scheduleFromTo;

    if (dto.id) {
      update(dto)
    } else {
      create(dto)
    }
  };

  const update = dto => {
    updateTastamat(dto)
      .then(() => close())
      .catch(() => Swal.fire(t('notEdited'), '', 'error'))
  }

  const create = dto => {
    if (isPp && ppCapacity) {
      dto.calibrated = true
    }

    createTastamat(dto)
      .then(({ data }) => {
        if (isPp && ppCapacity && data && data.id) {
          createPp(data.id)
        } else {
          close()
        }
      })
      .catch(() => Swal.fire(this.props.t('notCreated'), '', 'error'))
  };

  const createPp = lockerId => {
    createPickPoint(lockerId, ppCapacity)
      .then(() => close())
      .catch(err => handleError(err))
  }

  const determineType = name => {
    const numberTypes = ['size', 'rows', 'columns', 'latitude', 'longitude'];
    const radioTypes = ['type', 'access'];
    if (numberTypes.includes(name))
      return 'number';
    else if (radioTypes.includes(name))
      return 'radio';
    else
      return 'text'
  };

  const filterFields = ([key]) => {
    const fieldKey = key.substring(0, key.length - 2);
    return haveLang.includes(fieldKey)
      ? key.includes(chosenLang)
      : true
  }

  const changeView = value => {
    setView(view === value ? '' : value);
  }

  const updateFreeDay = (key, value, state = true) => {
    if (state) {
      console.log("FREE");
      locker.schedule[key] = value === true ? '-' : ''
    }
    setFreeDay(existingValues => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [key]: value,
    }))
  }

  const updateWeekdays = (k, value) => {
    // dayOptions[key] = value === true ? '-' : ''
    Object.entries(locker.schedule).forEach(entry => {
      let [key] = entry;

      if (key !== 'scheduleMonday' && key !== 'scheduleSaturday' && key !== 'scheduleSunday') {
        updateFreeDay(key, value, false);
      }
    });
    setDayOptions(existingValues => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [k]: value,
    }))
  }

  const updateAllDays = (k, value) => {
    Object.entries(locker.schedule).forEach(entry => {
      let [key] = entry;

      if (key !== 'scheduleMonday') {
        updateFreeDay(key, value, false);
      }
    });
    setDayOptions(existingValues => ({
      // Retain the existing values
      ...existingValues,
      // update the current field
      [k]: value,
    }))
  }

  return locker ? (
    <Modal size="medium" title={locker.index} closeModal={close}>
      <div className="admin__langButtons">
        {langs.map((lang, index) => (
          <LangBtn key={index} active={chosenLang === lang} onClick={() => setChosenLang(lang)}>
            {lang === 'Kk' ? 'Kg' : lang}
          </LangBtn>
        ))}
      </div>
      <Form active={view === 'params'}>
        <LabelWithBorder onClick={() => changeView('params')}>
          {t('systemParams')}
        </LabelWithBorder>
        {Object.entries(locker.params).map(([key, value], index) => (
          <BasicInput
            key={index}
            label={t(key)}
            name={key}
            value={locker.params[key]}
            type={determineType(key)}
            onChange={e => handleChange('params', e)}
            required
          />
        ))}
      </Form>
      <Form active={view === 'address'}>
        <LabelWithBorder onClick={() => changeView('address')}>
          {t('address')}
        </LabelWithBorder>
        {Object.entries(locker.address).filter(filterFields).map(([key, value], index) => (
          <BasicInput
            key={index}
            label={t(key)}
            name={key}
            value={locker.address[key]}
            type={determineType(key)}
            onChange={e => handleChange('address', e)}
            required
          />
        ))}
      </Form>
      <Form active={view === 'location'}>
        <LabelWithBorder onClick={() => changeView('location')}>
          {t('location')}
        </LabelWithBorder>
        {Object.entries(locker.location).filter(filterFields).map(([key, value], index) => (
          determineType(key) === 'radio' ? (
            key === 'type' ?
              <div key={index}>
                <Label>{t('type')} *</Label>
                {
                  types.map((type, i) => (
                    <Radio
                      key={i}
                      id={type}
                      name={'type'}
                      title={t(type)}
                      value={type}
                      checked={locker.location.type === type}
                      onChange={e => handleChange('location', e)}
                    />
                  ))
                }
              </div> :
              <div key={index}>
                <Label>{t('type')} * {locker.location.access}</Label>
                {
                  accessTypes.map((access, i) => (
                    <Radio
                      key={i}
                      id={access}
                      name={'access'}
                      title={t(access)}
                      value={access}
                      checked={locker.location.access === access}
                      onChange={e => handleChange('location', e)}
                    />
                  ))
                }
              </div>
            ) :
            <BasicInput
              key={index}
              label={t(key)}
              name={key}
              value={locker.location[key]}
              type={determineType(key)}
              onChange={e => handleChange('location', e)}
              required
            />
        ))}
      </Form>
      <Form active={view === 'schedule'}>
          <LabelWithBorder onClick={() => changeView('schedule')}>
            {t('schedule')}
          </LabelWithBorder>
          <div>
            <FlexRow innerSpace="1em" marginBottom="0.5em">
              <Checkbox
                id={'weekdays'}
                name={'weekdays'}
                title={t('weekdays')}
                checked={dayOptions['weekdays']}
                onChange={({ target }) => updateWeekdays('weekdays', target.checked)}
              />
              <Checkbox
                id={'alldays'}
                name={'alldays'}
                title={t('alldays')}
                checked={dayOptions['alldays']}
                onChange={({ target }) => updateAllDays('alldays', target.checked)}
              />
              </FlexRow>
          </div>
          {Object.entries(locker.schedule).map(([key, value], index) => (
            <div key={index}>
              <div>
                <Label>{t(key)} *</Label>
                <Checkbox
                  id={'isFreeDay' + key}
                  name={'isFreeDay' + key}
                  title={t('freeDay')}
                  checked={isFreeDay[key]}
                  disabled={(dayOptions['alldays'] || dayOptions['weekdays']) && key === 'scheduleMonday'}
                  onChange={({ target }) => updateFreeDay(key, target.checked)}
                />
              </div>
              
              <FlexRow innerSpace="1em" marginBottom="0.5em">
                <BasicInput
                  key={key + ',From'}
                  label={t('from')}
                  name={key + ',From'}
                  disabled={value === '-' || (dayOptions['weekdays'] === true && key !== 'scheduleMonday' && key !== 'scheduleSaturday' && key !== 'scheduleSunday') || (dayOptions['alldays'] === true && key !== 'scheduleMonday')}
                  value={locker.scheduleFromTo[key+',From']}
                  // value={locker.schedule[key].split('-')[0]}
                  type='time'
                  onChange={e => handleChange('scheduleFromTo', e)}
                />
                <BasicInput
                  key={key + ',To'}
                  label={t('to')}
                  name={key + ',To'}
                  disabled={value === '-' || (dayOptions['weekdays'] === true && key !== 'scheduleMonday' && key !== 'scheduleSaturday' && key !== 'scheduleSunday') || (dayOptions['alldays'] === true && key !== 'scheduleMonday')}
                  value={locker.scheduleFromTo[key+',To']}
                  // value={locker.schedule[key].split('-')[1]}
                  type='time'
                  onChange={e => handleChange('scheduleFromTo', e)}
                />
              </FlexRow>
              
            </div>
          ))}
        </Form>
      {locker.partner && (
        <Form active={view === 'partner'}>
          <LabelWithBorder onClick={() => changeView('partner')}>
            {t('partner')}
          </LabelWithBorder>
          {Object.entries(locker.partner).map(([key, value], index) => (
            <BasicInput
              key={index}
              label={t(key)}
              name={key}
              value={locker.partner[key]}
              type={determineType(key)}
              onChange={e => handleChange('partner', e)}
              required
            />
          ))}
        </Form>
      )}
      <FlexColumn innerSpace="0.25em" marginTop="1em" fullWidth>
        <Checkbox
          id={'isPp'}
          name={'isPp'}
          title={t('isPp')}
          checked={isPp}
          onChange={({ target }) => setIsPp(target.checked)}
        />
        {
          isPp ?
            <BasicInput
              type={'text'}
              label={t('ppCapacity')}
              name="ppCapacity"
              value={ppCapacity}
              onChange={({ target }) => setPpCapacity(target.value)}
              required
            />
            : null
        }
      </FlexColumn>
      <FlexRow justifyContent="flex-end" margin="2em 0 0">
        <ActionButton
          type="submit"
          size="small"
          title={t('actions.save')}
          icon="save"
          click={saveTastamat}
        />
      </FlexRow>
    </Modal>
  ) : null;
}

const mapStateToProps = state => ({ role: state.currentUser.role });

export default withNamespaces('addLockerInfo')(
  connect(mapStateToProps)(ChangeLockerInfo)
);
