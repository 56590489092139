export const cities = [
  {
    name: {
      ru: 'Бишкек',
      kk: 'Бишкек',
      en: 'Bishkek'
    },
    code: '01',
    location: {
      lat: 42.850092,
      lng: 74.617078
    }
  },
  {
    name: {
      ru: 'Ош',
      kk: 'Ош',
      en: 'Osh'
    },
    code: '02',
    location: {
      lat: 40.518783,
      lng: 72.821439
    }
  }
];
