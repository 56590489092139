import * as React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import MainLayout from "../layouts/main";
import { handleError } from "../services/other.service";
import {
  getOrders,
  getOrderById,
  changeForWithdrawal
} from "../services/tracking.service";
import { Title } from "../components/title.js";
import { Search } from "../components/searchInput.js";
import { Status } from "../components/status.js";
import { Flex, Row } from "../components/flexboxes.js";
import Pagination from "../components/pagination";
import {
  Table,
  Tr,
  Th,
  Td,
  InformButton
} from "../components/flexibleTable.js";
import OrderDetails from "./modals/orderDetails";
import BookCell from "./modals/bookCell";
import SortByPeriod from "../components/sortByPeriod";
import CustomSelect from "../components/customSelect";
import { orderStatuses } from "../resources/constants/statuses";
import { ActionButton, Refresh } from "../components/button";
import { FlexRow } from "../components/flexboxes";

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      chosenOrder: [],
      showModal: "",
      page: 0,
      ordersCount: 0,
      perPage: props.cookies.get("tableRowsPerPage") || 10,
      status: "ALL",
      sortBy: "",
      order: "",
      from: "",
      to: "",
      searchKey: '',
      refreshing: false
    };
  }

  componentDidMount() {
    this.getOrders();
  }

  getOrders() {
    const { page, perPage, searchKey, status, sortBy, order, from, to } = this.state;
    getOrders(perPage, page, searchKey, status, sortBy, order, from, to)
      .then(res =>
        this.setState({
          orders: res.data.list,
          ordersCount: res.data.count,
          refreshing: false
        })
      )
      .catch(err => handleError(err));
  }

  refresh = () => {
    this.setState({ refreshing: true }, () => this.getOrders(null));
  };

  searchByKey = () => {
    this.setState({
      page: 0
    }, () => this.getOrders())
  }

  loadOrderById = id => {
    getOrderById(id)
      .then(res =>
        this.setState({ chosenOrder: res.data, showModal: "orderModal" })
      )
      .catch(err => handleError(err));
  };

  onPageChanged = data => {
    const { currentPage } = data;
    this.setState({ page: currentPage - 1 }, () => this.getOrders());
  };

  changeForWithdrawal = type => {
    changeForWithdrawal(this.state.chosenOrder.id, type)
      .then(res => this.loadOrderById(this.state.chosenOrder.id))
      .catch(err => handleError(err));
  };

  sortBy = value => {
    this.setState(
      prevState => ({
        page: 0,
        sortBy: value,
        order:
          prevState.order === "asc" && prevState.sortBy === value
            ? "desc"
            : "asc"
      }),
      () => {
        this.getOrders();
      }
    );
  };

  sortOrdersByPeriod = (from, to) => {
    this.setState(
      {
        page: 0,
        from: from ? new Date(from).getTime() : null,
        to: to ? new Date(to).getTime() : null
      },
      () => this.getOrders()
    );
  };

  changeStatus = status => {
    this.setState({ page: 0, status }, () =>
      this.getOrders()
    );
  };

  closeModal = (refresh, reloadPage) => {
    this.setState(
      {
        page: reloadPage ? 0 : this.state.page,
        showModal: ""
      },
      () => {
        if (refresh) this.refresh();
      }
    );
  };

  render() {
    const { t, currentUser } = this.props;
    const {
      sortBy,
      order,
      ordersCount,
      perPage,
      page,
      searchKey
    } = this.state;

    const tableHeaders = [
      { title: t("trackNumber") },
      { title: t("status") },
      {
        title: t("cellBookingTime"),
        value: "bookDate",
        icon:
          sortBy === "bookDate" && order === "asc" ? (
            <i className="material-icons">arrow_drop_down</i>
          ) : sortBy === "bookDate" && order === "desc" ? (
            <i className="material-icons">arrow_drop_up</i>
          ) : null
      },
      {
        title: t("lastStatusTime"),
        value: "modifyDate",
        icon:
          sortBy === "modifyDate" && order === "asc" ? (
            <i className="material-icons">arrow_drop_down</i>
          ) : sortBy === "modifyDate" && order === "desc" ? (
            <i className="material-icons">arrow_drop_up</i>
          ) : null
      },
      { title: "" }
    ];

    return (
      <React.Fragment>
        <Row
          justifyContent="space-between"
          alignItems="center"
          marginBottom="0.5rem"
          fullWidth
        >
          <Row justifyContent="flex-start" alignItems="center" fullWidth>
            <Title>{t("title")}</Title>
            <Refresh click={this.refresh} isLoading={this.state.refreshing} />
          </Row>
          {currentUser.role === "ADMIN" || (currentUser.system && currentUser.system.verified)  ? (
        <ActionButton
          size="small"
          icon="add"
          title={t("bookCell")}
          click={() => this.setState({ showModal: "bookModal" })}
        />
          ) : null}
        </Row>
        <SortByPeriod parentCallback={this.sortOrdersByPeriod} />
        <Row justifyContent="space-between" marginBottom="0.5em" fullWidth>
          <Row justifyContent="flex-start" fullWidth>
            <Search
              value={searchKey}
              placeholder={t('search')}
              onChange={e => this.setState({ searchKey: e.target.value })}
              submit={this.searchByKey}
            />
            <CustomSelect
              value={t(`statuses:${this.state.status}`)}
              options={orderStatuses}
              click={this.changeStatus}
            />
          </Row>
        </Row>
        <Row justifyContent="flex-end" marginBottom="0.5em" fullWidth>
          <Pagination
            totalRecords={ordersCount}
            currentPage={page + 1}
            pageLimit={perPage}
            pageNeighbours={1}
            onPageChanged={this.onPageChanged}
          />
        </Row>
        <Table
          fullWidth
          headers={tableHeaders.map((h, index) => (
            <Th
              key={index}
              onClick={h.value ? () => this.sortBy(h.value) : null}
              clickable={h.value}
            >
              <Flex justifyContent={"center"} alignItems={"center"}>
                {h.title}
                {h.icon}
              </Flex>
            </Th>
          ))}
        >
          {this.state.orders.length > 0 ? (
            this.state.orders.map((o, index) => (
              <Tr key={index}>
                <Td>{o.identificator}</Td>
                <Td>
                  <Status
                    type={
                      o.onWithdraw && o.status === "SENT"
                        ? "onWITHDRAWN"
                        : o.status
                    }
                  >
                    {t(
                      `statuses:${
                        o.onWithdraw && o.status === "SENT"
                          ? "onWITHDRAWN"
                          : o.status
                      }`
                    )}
                  </Status>
                </Td>
                <Td>{moment(o.bookDate).format("DD.MM.YY - HH:mm")}</Td>
                <Td>
                  {o.status === "RESERVED"
                    ? moment(o.bookDate).format("DD.MM.YY - HH:mm")
                    : o.status === "UNRESERVED"
                    ? moment(o.unbookDate).format("DD.MM.YY - HH:mm")
                    : o.status === "SENT"
                    ? moment(o.dropDate).format("DD.MM.YY - HH:mm")
                    : o.status === "END"
                    ? moment(o.pickDate).format("DD.MM.YY - HH:mm")
                    : o.status === "WITHDRAWN"
                    ? moment(o.withdrawDate).format("DD.MM.YY - HH:mm")
                    : null}
                </Td>
                <Td>
                  <FlexRow justifyContent="center" innerSpace="0.25rem">
                    <InformButton
                      title={t("actions:seeInfo")}
                      onClick={() => this.loadOrderById(o.id)}
                    >
                      i
                    </InformButton>
                  </FlexRow>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={5}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
        {this.state.showModal === "orderModal" ? (
          <OrderDetails
            order={this.state.chosenOrder}
            changeForWithdrawal={this.changeForWithdrawal}
            close={(refresh, reloadPage) =>
              this.closeModal(refresh, reloadPage)
            }
            reload={() => this.loadOrderById(this.state.chosenOrder.id)}
          />
        ) : this.state.showModal === "bookModal" ? (
          <BookCell
            close={(refresh, reloadPage) =>
              this.closeModal(refresh, reloadPage)
            }
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default withNamespaces("tracking")(
  MainLayout(connect(mapStateToProps)(Tracking))
);
