import * as React from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import MainLayout from "../layouts/main";
import { getLockers, getLockerById } from "../services/lockers.service";
import { handleError, capitalize } from "../services/other.service";
import { FlexRow, Row, ActionButton, Refresh } from "../components";
import { Title } from "../components/title.js";
import { Search } from "../components/searchInput.js";
import { Selector, SelectorButton } from "../components/selector.js";
import { Table, Tr, Td, InformButton } from "../components/flexibleTable.js";
import MonitoringMap from "../components/monitoringMap";
import LockerInfo from "./modals/lockerInfo";
import ChangeLockerInfo from "./modals/changeLockerInfo";
import CustomSelect from "../components/customSelect";
import { cities } from "../resources/constants/cities";
import { Th } from "../components/flexibleTable";
import { lockerStatuses, lockerStatusesImgs } from "../resources/constants/statuses";
import Pagination from "../components/pagination";

export const LockerStatus = styled.span`
  position: relative;
  font-weight: bold;
  line-height: normal;
  font-size: 1em;
  padding-left: 2em;
  color: ${props =>
    props.status === "ON"
      ? "#208C25"
      : props.status === "OFF"
      ? "#F05A28"
      : props.status === "INACTIVE"
      ? "#C2C2C3"
      : "#000"};
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    left: 0;
    top: calc(50% - 0.75em);
    width: 1.5em;
    height: 1.5em;
    background: transparent url("${props =>
      lockerStatusesImgs[props.status]}") no-repeat;
    background-size: contain;
  }
`;

class Lockers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: "table",
      lockers: [],
      chosenLocker: {},
      modalOpen: false,
      modalType: "",
      city: {
        ru: 'Бишкек',
        kk: 'Бишкек',
        en: 'Bishkek'
      },
      region: "01",
      mapCenter: [42.850092, 74.617078],
      status: "ALL",
      refreshing: false,
      searchKey: '',
      page: 0,
      lockersCount: 0,
      perPage: props.cookies.get("tableRowsPerPage") || 10,
    };
  }

  componentDidMount() {
    this.getLockers();
  }

  searchByKey = () => {
    this.setState({
      page: 0
    }, () => this.getLockers())
  }

  getLockers = () => {
    const { page, perPage, region, status, searchKey } = this.state;
    getLockers(page, perPage, region, status, searchKey)
      .then(res => {
        this.setState(
          {
            lockers: res.data.list,
            lockersCount: res.data.count,
            refreshing: false
          },
          async () => {
            try {
              this.monitoringMap.setCenter();
              this.monitoringMap.getLockers();
            } catch (e) {}
          }
        );
      })
      .catch(err => handleError(err));
  };

  getLocker = async (id, type) => {
    await getLockerById(id)
      .then(({ data }) =>
        this.setState(
          {
            chosenLocker: {
              ...data,
              params: {
                code: data.code || '',
                index: data.index || '',
                oldIndex: data.oldIndex || '',
                size: data.size || '',
                rows: data.rows || '',
                columns: data.columns || '',
                presenceCode: data.presenceCode || '',
                gisUrl: data.gisUrl || '',
              }
            }
          },
          () => this.openModal(type)
        )
      )
      .catch(err => handleError(err));
  };

  openModal = modalName => {
    this.setState({
      modalOpen: true,
      modalType: modalName
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      modalType: ""
    });
  };

  changeCity = city => {
    this.setState({
      region: city.code,
      city: city.name,
      mapCenter: [city.location.lat, city.location.lng]
    }, () => this.getLockers())
  }

  onPageChanged = data => {
    const { currentPage } = data;
    this.setState({ page: currentPage - 1 }, () => this.getLockers());
  };

  changeStatus = status => {
    this.setState({ status }, () => this.getLockers());
  };

  refresh = () => {
    this.setState({ refreshing: true }, () => this.getLockers());
  };

  render() {
    const { i18n, t, currentUser } = this.props;
    const {
      view,
      lockers,
      chosenLocker,
      modalOpen,
      modalType,
      lockersCount,
      perPage,
      page,
      searchKey
    } = this.state;

    const tableHeaders =
      currentUser.role === "ADMIN"
        ? [
            t("lockerIndex"),
            t("address"),
            t("currentlyBooked"),
            t("currentlyHasParcels"),
            t("allTimePicked"),
            t("allTimeWithdrawn"),
            t("rating"),
            t("")
          ]
        : currentUser.role === "COURIER"
        ? [t("lockerIndex"), t("address"), t("")]
        : [
            t("lockerIndex"),
            t("address"),
            t("currentlyBooked"),
            t("currentlyHasParcels"),
            t("allTimePicked"),
            t("allTimeWithdrawn"),
            t("")
          ];

    return (
      <React.Fragment>
        <Row
          justifyContent="space-between"
          alignItems="flex-start"
          marginBottom="1rem"
          fullWidth
        >
          <Row
            justifyContent="flex-start"
            alignItems="center"
            marginBottom="0.5em"
            fullWidth
          >
            <Title>{t("title")}</Title>
            <Refresh click={this.refresh} isLoading={this.state.refreshing} />
          </Row>
          <Selector>
            <SelectorButton
              active={view === "table"}
              onClick={() => this.setState({ view: "table" })}
            >
              {t("tableView")}
            </SelectorButton>
            <SelectorButton
              active={view === "map"}
              onClick={() => this.setState({ view: "map" })}
            >
              {t("mapView")}
            </SelectorButton>
          </Selector>
        </Row>
        <Row
          justifyContent="space-between"
          alignItems="flex-start"
          marginBottom="1rem"
          fullWidth
        >
          <FlexRow
            innerSpace="1rem"
            alignItems="center"
            marginBottom="1rem"
            fullWidth
            wrapItems
          >
            <CustomSelect
              value={this.state.city[i18n.language]}
              options={cities}
              click={this.changeCity}
            />
            <CustomSelect
              value={t(`statuses:${this.state.status}`)}
              options={lockerStatuses}
              click={this.changeStatus}
            />
            <Search
              value={searchKey}
              placeholder={t('search')}
              onChange={e => this.setState({ searchKey: e.target.value })}
              submit={this.searchByKey}
            />
          </FlexRow>
          {/*<XlsLink*/}
          {/*  onClick={() =>*/}
          {/*    downloadLockersXls(this.state.region, this.state.status)*/}
          {/*  }*/}
          {/*>*/}
          {/*  {t("global:downloadSheet")}*/}
          {/*</XlsLink>*/}

          {currentUser.role === "ADMIN" ? (
              <ActionButton
                  size="small"
                  icon="add"
                  title={t("addTastamat")}
                  click={() => this.openModal("addTastamat")}
              />
          ) : null}
        </Row>

        <Row justifyContent="flex-end" marginBottom="0.5em" fullWidth>
          <Pagination
              totalRecords={lockersCount}
              currentPage={page + 1}
              pageLimit={perPage}
              pageNeighbours={1}
              onPageChanged={this.onPageChanged}
          />
        </Row>

        {view === "table" ? (
          <React.Fragment>
            <Table
              headers={tableHeaders.map((h, index) => (
                <Th key={index}>{h}</Th>
              ))}
              fullWidth
            >
              {lockers.length > 0 ? (
                lockers.map((l, index) => (
                  <Tr key={index}>
                    <Td>
                      <LockerStatus status={l.locker.status}>
                        {l.locker.index}
                      </LockerStatus>
                    </Td>
                    <Td textAlign="left">
                      {
                        l.locker.address[
                          `buildingShort${capitalize(i18n.language)}`
                        ]
                      }
                      ,&nbsp;
                      {
                        l.locker.address[
                          `streetShort${capitalize(i18n.language)}`
                        ]
                      }
                      ,&nbsp;
                      {l.locker.address.buildingNumber}
                    </Td>
                    {currentUser.role !== "COURIER" ? (
                      <React.Fragment>
                        <Td>{l.booked}</Td>
                        <Td>{l.dropped}</Td>
                        <Td>{l.picked}</Td>
                        <Td>{l.withdrawn}</Td>
                        {currentUser.role === "ADMIN" ? (
                          <Td size="smallest">{l.rating}</Td>
                        ) : null}
                      </React.Fragment>
                    ) : null}
                    <Td>
                      <FlexRow justifyContent="center" innerSpace="0.25rem">
                        <InformButton
                          title={t("actions:seeInfo")}
                          onClick={() =>
                            this.getLocker(l.locker.id, "showInfo")
                          }
                        >
                          i
                        </InformButton>
                        {currentUser.role === "ADMIN" ? (
                          <InformButton
                            title={t("actions:editInfo")}
                            onClick={() =>
                              this.getLocker(l.locker.id, "editInfo")
                            }
                          >
                            <i className="material-icons">edit</i>
                          </InformButton>
                        ) : null}
                      </FlexRow>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td colSpan={currentUser.role === "ADMIN" ? 8 : 7}>
                    {t("global:nothingFound")}
                  </Td>
                </Tr>
              )}
            </Table>
          </React.Fragment>
        ) : view === "map" ? (
          <MonitoringMap
            onRef={ref => (this.monitoringMap = ref)}
            center={this.state.mapCenter}
            lockers={lockers}
            getLocker={this.getLocker}
          />
        ) : null}
        {modalOpen && modalType === "showInfo" ? (
          <LockerInfo
            locker={chosenLocker}
            close={this.closeModal}
            reload={() => this.getLocker(chosenLocker.id, "showInfo")}
          />
        ) : null}
        {modalOpen && modalType === 'editInfo' ? (
          <ChangeLockerInfo
            locker={chosenLocker}
            close={this.closeModal}
            reload={() => this.getLocker(chosenLocker.id, "editInfo")}
          />
        ) : null}
        {
          modalOpen && modalType === 'addTastamat'
            ? <ChangeLockerInfo close={this.closeModal} />
            : null
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser
});

export default withNamespaces("lockers")(
  MainLayout(connect(mapStateToProps)(Lockers))
);
