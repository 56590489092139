import * as React from "react";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import { connect } from "react-redux";
import MainLayout from "../layouts/main";
import { Title } from "../components/title.js";
import { Table, Tr, Th, Td } from "../components/flexibleTable.js";
import { ActionButton } from "../components/button.js";
import { Row, FlexRow } from "../components/flexboxes";
import { getSystems, handleError } from "../services/other.service";
import { Refresh } from "../components/button";
import { getTariffs, removeTariff } from "../services/billing.service";
import CreateTariff from "./modals/createTariff";
import CustomSelect, { Option } from "../components/customSelect";
import { InformButton } from "../components/flexibleTable";

class Tariffs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tariffs: [],
      system: {
        name: props.t("all")
      },
      refreshing: false,
      modalType: "",
      currentTariff: {}
    };
    getSystems();
  }

  componentDidMount() {
    this.getTariffList();
  }

  getTariffList = () => {
    getTariffs(this.state.system.id)
      .then(res => this.setState({ tariffs: res.data.list, refreshing: false }))
      .catch(err => handleError(err));
  };

  removeTariff = (systemId, id) => {
    removeTariff(systemId, id)
      .then(() => this.getTariffList())
      .catch(err => handleError(err));
  };

  refresh = () => {
    this.setState({ refreshing: true }, () => this.getTariffList());
  };

  closeModal = reload => {
    this.setState(
      {
        modalShow: false,
        currentTariff: {}
      },
      () => {
        if (reload === true) this.getTariffList();
      }
    );
  };

  render() {
    const { t, systems } = this.props;
    const { tariffs, system, modalType, currentTariff } = this.state;
    const tableHeaders = [
      { title: t("system") },
      { title: t("startDate") },
      { title: t("endDate") },
      { title: t("actions.title") }
    ];
    return (
      <React.Fragment>
        <Row justifyContent="space-between" marginBottom="0.5rem">
          <Row justifyContent="flex-start" alignItems="center" fullWidth>
            <Title>{t("title")}</Title>
            <Refresh click={this.refresh} isLoading={this.state.refreshing} />
          </Row>
          <FlexRow justifyContent="flex-start" innerSpace="0.5rem">
            <ActionButton
              size="small"
              icon="person_add"
              title={t("actions.create")}
              click={() =>
                this.setState({ modalShow: true, modalType: "create" })
              }
            />
          </FlexRow>
        </Row>
        <FlexRow
          innerSpace="1rem"
          alignItems="center"
          marginBottom="1rem"
          fullWidth
          wrapItems
        >
          <CustomSelect value={system.name || system.id}>
            <Option
              onClick={() =>
                this.setState({ system: { name: t("all") } }, () =>
                  this.getTariffList()
                )
              }
            >
              {t("all")}
            </Option>
            {systems.map((s, i) => (
              <Option
                key={i}
                onClick={() =>
                  this.setState({ system: s }, () => this.getTariffList())
                }
              >
                {s.name || s.id}
              </Option>
            ))}
          </CustomSelect>
        </FlexRow>
        <Table
          fullWidth
          headers={tableHeaders.map((h, index) => (
            <Th key={index}>{h.title}</Th>
          ))}
        >
          {tariffs.length > 0 ? (
            tariffs.map((tariff, index) => (
              <Tr key={index}>
                {/*<Td>{systems.find(system => system.id === tariff.systemId).name}</Td>*/}
                <Td>
                  {systems.length > 0
                    ? systems.find(system => system.id === tariff.systemId)
                        .name ||
                      systems.find(system => system.id === tariff.systemId).id
                    : tariff.systemId}
                </Td>
                <Td>{moment(tariff.startDate).format("DD.MM.YY")}</Td>
                <Td>{moment(tariff.endDate).format("DD.MM.YY")}</Td>
                <Td>
                  <FlexRow justifyContent="center" innerSpace="0.25rem">
                    <InformButton
                      title={t("actions:seeInfo")}
                      onClick={() =>
                        this.setState({
                          modalShow: true,
                          modalType: "show",
                          currentTariff: tariff
                        })
                      }
                    >
                      i
                    </InformButton>
                    {system.id && index === tariffs.length - 1 ? (
                      <InformButton
                        title={t("actions:removeTariff")}
                        onClick={() =>
                          this.removeTariff(tariff.systemId, tariff.id)
                        }
                      >
                        x
                      </InformButton>
                    ) : null}
                  </FlexRow>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4}>{t("global:nothingFound")}</Td>
            </Tr>
          )}
        </Table>
        {this.state.modalShow ? (
          <CreateTariff
            close={this.closeModal}
            type={modalType}
            currentTariff={currentTariff}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({ systems: state.systems });

export default withNamespaces("tariff")(
  MainLayout(connect(mapStateToProps)(Tariffs))
);
